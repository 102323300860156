<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VToolbar flat>
            <VBtn
              icon
              @click="$router.go(-1)"
            >
              <VIcon>fal fa-chevron-left</VIcon>
            </VBtn>

            <VToolbarTitle>Создание вопроса</VToolbarTitle>
          </VToolbar>
          <VCardText>
            <VForm ref="questionForm">
              <VRow>
                <VCol cols="10">
                  <VTextField
                    v-model="title"
                    label="Название вопроса"
                  />
                  <VTextField
                    v-model="level"
                    label="Уровень"
                  />
                  <TextEditor
                    v-model="description"
                    label="Описание"
                    class="mt-4"
                  />
                  <TextEditor
                    v-model="rightAnswerComment"
                    label="Комментарий к верному ответу"
                    class="mt-8 mb-4"
                  />

                  <VBtn
                    depressed
                    color="primary"
                    @click="createQuestion(testId, topicId)"
                  >
                    Создать
                  </VBtn>
                </VCol>
              </VRow>
            </VForm>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import TextEditor from '../../TextEditor.vue';

export default {
  name: 'QuestionCreate',
  components: { TextEditor },
  inject: ['Names'],
  data() {
    return {
      title: '',
      level: '',
      description: '',
      rightAnswerComment: '',
    };
  },
  computed: {
    testId() {
      return this.$route.params.testId;
    },
    topicId() {
      return this.$route.params.topicId;
    },
  },
  methods: {
    async createQuestion(testId, topicId) {
      try {
        const {
          title, level, description, rightAnswerComment,
        } = this;

        const questionResponse = await this.$di.api.Talent.questionCreate({
          testId, topicId, title, level, description, rightAnswerComment,
        });
        this.$di.notify.snackSuccess('Вопрос успешно создан');

        this.$router.push({
          name: this.Names.R_TALENT_TEST_TOPIC_QUESTION_EDIT,
          params: {
            ...this.$route.params,
            questionId: questionResponse.id,
          },
        });
        this.clear();
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },
    clear() {
      this.$refs.questionForm.reset();
    },
  },
};
</script>
